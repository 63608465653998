import $ from "jquery";
require("jquery-autocomplete");

const SuggestionTypeEnum = Object.freeze({
    System: 0,
    Event: 1,
    PopularKeyword: 2,
    Category: 3
});

var currentSearchText = "";

function GetSuggestionIcon(item) {
    let iconClass;
    switch (item.SuggestionType) {
        case SuggestionTypeEnum.System:
            iconClass = 'bi bi-shop';
            break;
        case SuggestionTypeEnum.Event:
            iconClass = 'bi bi-calendar2-event';
            break;
        case SuggestionTypeEnum.PopularKeyword:
            iconClass = 'bi bi-search';
            break;
        case SuggestionTypeEnum.Category:
            iconClass = 'bi bi-bookmark';
            break;
    }

    return `<i class="body-text ${iconClass}"> </i>`;
}

function initSuggestionAutocomplete() {

    $(".searchsuggestion").autocomplete({
        dropdownWidth: 'auto',
        dropdownStyle: { 'max-width': '100%' },
        appendMethod: "replace",
        minLength: 3,
        limit: 15,
        autoselect: false,
        valid: function () {
            return true;
        },
        source: [
            function (text, add) {
                if (!text) {
                    return;
                }

                currentSearchText = text;

                var categoryId = $("#categoryId").val();
                var searchType = $("#type").val();

                var url = window.ApiUrl + "Search/autocomplete?term=" + text;

                if (categoryId != null) {
                    url += "&categoryId=" + categoryId;
                }

                if (searchType != null && searchType != "BookingSystems") {
                    url += "&showSystemsFirst=false";
                }

                $.getJSON(url, function (data) {
                    if (data && data.length > 0) {
                        add(data);
                    }
                });
            }
        ],
        getTitle: function (item) {
            return item.SuggestionTitle;
        },
        getValue: function (item) {
            return item.SuggestionTitle;
        },
        render: function (item, source, pid, query) {
            var value = item.SuggestionTitle,
                title = item.SuggestionTitle,
                dateTime = item.EventDateTime,
                location = item.SuggestionLocation;

            var suggestionIcon = GetSuggestionIcon(item);

            let displayElement = `<div class="d-flex flex-wrap flex-sm-nowrap align-items-end ${item.SuggestionTitle == query ? 'active' : ''}">${suggestionIcon}<span class="search-item-title fw-medium text-truncate">${title}</span>`;
            
            if (dateTime) {
                displayElement += `<span class="fs-16 text-truncate">${dateTime}</span>`;
            }
            if (location) {
                displayElement += `<span class="fs-16 text-truncate">${location}</span>`;
            }
            displayElement += "</div>";
            return displayElement;
        }
    }).on('selected.xdsoft', function (e, suggestion) {
        if (suggestion.Uri != null) {
            window.location.href = suggestion.Uri;
        }
        else {
            $(".searchsuggestion").closest("form").trigger("submit");
        }
    });

    $(".searchsuggestion").keydown(function (evt) {
        if (evt.keyCode === 9 /* TAB */) {
            $(".searchsuggestion").val(currentSearchText);
        }
    });
}

function fixLayoutForAutoComplete() {
    let searchInput = $(".form-floating .xdsoft_autocomplete");
    if (searchInput) {
        searchInput.addClass("form-floating"); 
    }
}

(function () {
    initSuggestionAutocomplete();
    fixLayoutForAutoComplete();
    
})();
